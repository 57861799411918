import { $win } from '../utils/globals';

const barHeight = $('.bar').outerHeight();

$win.on('scroll', function () {
	const winScroll = $win.scrollTop();

	if ( winScroll >= 10 ) {
		$('.header').css('top', -barHeight)
		$('.header').addClass('has-bg');
	} else {
		$('.header').css('top', 0)
		$('.header').removeClass('has-bg');
	}
})
