import 'slick-carousel';

const $slider = $('.js-slider .slider__slides');

$slider.slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: true,
});
