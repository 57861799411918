$('.js-quantity').on('change', function(){
	const $this = $(this);

	if ( $('.cart .quantity > .qty').length ) {
		$('.cart .quantity > .qty').attr( 'value', $this.find('.qty').val()).trigger('change');
	}

	if ( $('.ajax_add_to_cart').length ) {
		$('.ajax_add_to_cart').attr( 'data-quantity', $this.find('.qty').val()).trigger('change');
	}
});
