import { $win } from '../utils/globals';

$.fn.equalizeHeight = function() {
	var maxHeight = 0, itemHeight;

	for (var i = 0; i < this.length; i++) {
		itemHeight = $(this[i]).height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}

	return this.height(maxHeight);
}

$win.on('load', function () {
	$('.circle-boxes .circle__box-head').equalizeHeight();
	$('.section-products .products.columns-3 .product > .woocommerce-loop-product__link').equalizeHeight();
	$('.section-products .products.columns-3 .product > .product__meta').equalizeHeight();
})

$win.on('resize', function() {
	if ( $win.width() > 767 ) {
		$('.circle-boxes .circle__box-head').removeAttr('style').equalizeHeight();
		$('.section-products .products.columns-3 .product > .woocommerce-loop-product__link').removeAttr('style').equalizeHeight();
		$('.section-products .products.columns-3 .product > .product__meta').removeAttr('style').equalizeHeight();
	} else {
		$('.circle-boxes .circle__box-head').removeAttr('style');
		$('.section-products .products.columns-3 .product > .woocommerce-loop-product__link').removeAttr('style');
		$('.section-products .products.columns-3 .product > .product__meta').removeAttr('style');
	}
})
