import imagesLoaded from 'imagesloaded';
const jQueryBridget = require('jquery-bridget');
const Isotope = require('isotope-layout');

jQueryBridget( 'isotope', Isotope, $ );

const $services = $('.services').isotope({
	itemSelector: '.service',
	percentPosition: true,
});

$services.imagesLoaded().progress( function() {
	$services.isotope('layout');
});
