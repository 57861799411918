/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */
 import './modules/header';
 import './modules/btn-hover';
 import './modules/equalize';
 import './modules/slider';
 import './modules/services';
 import './modules/tiles';
 import './modules/nav';
 import './modules/iframe-magnific';
 import './modules/filter';
 import './modules/parallax-custom';
 import './modules/aos-animations';
 import './modules/products-quantity';

