import { $win } from '../utils/globals';

Math.trunc = Math.trunc || function(x) {
	if (isNaN(x)) {
		return NaN;
	}
	if (x > 0) {
		return Math.floor(x);
	}

	return Math.ceil(x);
};

const $btn = $('.btn-container');

$btn.each( function () {

	$(this).on('mouseover', function (e) {
		$(this).addClass('is-active');
	});

	$(this).on('mouseleave', function () {
		$(this).removeClass('is-active');
	});


	var offset = $(".btn__overlay").offset();

	$(this).on('mousemove', function (e) {
		var rect = e.target.getBoundingClientRect();
		var x = Math.trunc(e.clientX - rect.left);
		var y = Math.trunc(e.clientY - rect.top);

		var xText = Math.trunc(e.offsetX);

		$(this).find('.btn__overlay').css({
			left: x,
			top: y
		})
	})

})

